import React, { FC, ReactNode, ReactElement, useMemo } from 'react';
import { useTooltip } from '../../hooks/useTooltip';
import { FloatingPortal } from '@floating-ui/react';
import { TooltipProvider } from '../../contexts/TooltipContext';

type TooltipProps = {
  text: ReactElement | ReactNode | string | number | boolean | null | undefined;
  truncatedTextMode?: boolean; // when true, search for truncated text, and only show the tooltip if found
  children: (props: Record<string, unknown>) => ReactElement | HTMLElement;
  style?: 'dark' | 'light';
};

const Tooltip: FC<TooltipProps> = (props) => {
  const { text, truncatedTextMode, children, style = 'dark' } = props;

  const { isActive, setIsBlockedByChild, anchorProps, floatingProps, arrowEl } = useTooltip(truncatedTextMode, 'tooltip', !!text);

  let styleCss = 'text-white bg-primary-1 border-primary-1';
  switch (style) {
    case 'dark':
      styleCss = 'text-white bg-primary-1 border-primary-1';
      break;
    case 'light':
      styleCss = 'text-primary-1 bg-white border-gray-4';
      break;
  }

  const contextValue = useMemo(() => ({ setBlocking: setIsBlockedByChild }), [setIsBlockedByChild]);

  return (
    <>
      <TooltipProvider value={contextValue}>
        <>{children(anchorProps)}</>
      </TooltipProvider>
      {typeof text !== 'boolean' && !!text && isActive && (
        <FloatingPortal id="tooltip-root">
          <div className={`${style} ${styleCss} border-1 override-link-colour rounded-md px-3 py-2 shadow-md`} {...floatingProps}>
            {arrowEl}
            {text}
          </div>
        </FloatingPortal>
      )}
    </>
  );
};

export default React.memo(Tooltip);
