import { lazy } from 'react';
import RouteWithTitle from '../models/RouteWithTitle';

const publicRoutes: RouteWithTitle[] = [
  {
    id: 'data-protection-information',
    path: '/legal/data-protection-information',
    translationKey: 'page.data-protection-information',
    component: lazy(() => import('../pages/public/DataProtectionInformation')),
    publicPage: true,
    layout: 'Auth',
  },
  {
    id: 'terms-and-conditions',
    path: '/legal/terms-and-conditions',
    translationKey: 'page.terms-and-conditions',
    component: lazy(() => import('../pages/public/TermsAndConditions')),
    publicPage: true,
    layout: 'Auth',
  },
  {
    id: 'public-form',
    path: '/public/:dummy/:publicFormId',
    translationKey: 'page.public-form',
    component: lazy(() => import('../pages/form/PublicFormPage')),
    publicPage: true,
    layout: 'Minimal',
  },
  {
    id: 'login',
    path: '/auth/login',
    translationKey: 'page.login',
    component: lazy(() => import('../pages/auth/login/LoginFlow')),
    publicPage: true,
    layout: 'Auth',
  },
  {
    id: 'register',
    path: '/auth/register',
    translationKey: 'page.register',
    component: lazy(() => import('../pages/auth/register/RegisterFlow')),
    publicPage: true,
    layout: 'Auth',
  },
  {
    id: 'forgot-password',
    path: '/auth/forgot-password',
    translationKey: 'page.forgot-password',
    component: lazy(() => import('../pages/auth/forgot-password/ForgotPassword')),
    publicPage: true,
    layout: 'Auth',
  },
  {
    id: 'reset-password',
    path: '/auth/reset-password',
    translationKey: 'page.reset-password',
    component: lazy(() => import('../pages/auth/reset-password/ResetPassword')),
    publicPage: true,
    layout: 'Auth',
  },
  {
    id: 'public-acknowledgement',
    path: '/acknowledgement',
    translationKey: 'page.public-acknowledgement',
    component: lazy(() => import('../pages/form/PublicAcknowledgementPage')),
    publicPage: true,
    layout: 'Minimal',
  },
  {
    id: 'saml-login',
    path: '/auth/:clientId/saml-login',
    translationKey: 'page.saml-login',
    component: lazy(() => import('../pages/auth/saml/SamlRedirect')),
    publicPage: true,
    layout: 'Auth',
  },
  {
    id: 'soo-token-exchange',
    path: '/auth/sso-callback',
    translationKey: 'page.saml-login',
    component: lazy(() => import('../pages/auth/saml/SamlCallback')),
    publicPage: true,
    layout: 'Auth',
  },
];

export default publicRoutes;
