import { MouseEvent, ReactElement, useMemo } from 'react';
import useSlot from '../../../hooks/useSlots';
import { ariaAttributeProps, dataAttributeProps } from '../../../utils/ComponentUtils';
import withSlots, { SlotDefinitions } from '../../../wrappers/withSlot';
import Loader from '../Loader';
import Tooltip from '../Tooltip';

export const iconButtonContainerClasses =
  'flex items-center justify-center rounded-md bg-gray-6 text-gray-2 max-h-[30px] h-[30px] min-w-[30px] px-1 cursor-pointer hover:bg-gray-5';
export const iconButtonClasses = `${iconButtonContainerClasses} w-9 h-8 py-1 `;
export const iconButtonDisabledClasses = `pointer-events-none opacity-50`;

type ButtonProps = {
  id?: string;
  type?: ButtonType;
  name?: string;
  htmlType?: 'button' | 'submit' | 'reset' | undefined;
  disabled?: boolean;
  className?: string; // This prop maps to additional classes that can be added from where the component is being used
  borderClass?: string;
  size?: ButtonSize;
  ignoreMinWidth?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  title?: string | ReactElement;
  toggleEnabled?: boolean;
  loading?: boolean;
  containerClassName?: string;
};

export enum ButtonType {
  PRIMARY,
  SECONDARY,
  TOGGLE,
  TERTIARY,
}

export enum ButtonSize {
  S,
  M,
}

const Button = withSlots<ButtonProps, SlotDefinitions<['Icon']>>((props) => {
  const {
    className,
    disabled,
    onClick,
    htmlType = 'button',
    children,
    name,
    size,
    type,
    borderClass = 'rounded-lg',
    ignoreMinWidth,
    title,
    toggleEnabled,
    loading,
    containerClassName: constainerClassName = 'inline-block',
  } = props;

  const IconTemplate = useSlot(props, 'Icon');

  const disabledClasses = useMemo(() => (disabled || loading ? 'cursor-not-allowed' : 'hover:scale-105 focus:scale-105'), [disabled, loading]);

  // Different button type styles
  const buttonClasses = useMemo(() => {
    switch (type) {
      default:
      case ButtonType.PRIMARY:
        return `${
          disabled || loading
            ? 'bg-button-primary-disabled text-button-primary-pressed'
            : 'bg-button-primary-default active:bg-button-primary-pressed text-white'
        } font-medium border-2 border-transparent shadow-md`;
      case ButtonType.SECONDARY:
        return `${
          disabled || loading
            ? 'border-button-secondary-disabled text-button-secondary-disabled'
            : 'border-button-secondary-default active:border-button-secondary-pressed text-button-secondary-default active:text-button-secondary-pressed'
        } bg-transparent font-medium border-2`;
      case ButtonType.TOGGLE:
        return `${disabled || loading ? 'opacity-50' : ''} ${
          toggleEnabled ? 'bg-button-toggle-selected text-white' : 'bg-button-toggle-deselected text-button-toggle-selected'
        } font-medium py-2 px-4 border-0 active:border-0 shadow-md`;
      case ButtonType.TERTIARY:
        return `${
          disabled || loading
            ? '[&>div>.button-text]:after:border-current text-button-secondary-disabled'
            : '[&>div>.button-text]:after:border-current [&>div>.button-text]:after:focus:border-current'
        } bg-transparent relative font-medium [&>div>.button-text]:after:border-b-2 [&>div>.button-text]:after:w-full [&>div>.button-text]:after:absolute [&>div>.button-text]:after:-bottom-[1px]`;
    }
  }, [disabled, loading, toggleEnabled, type]);

  const buttonSizeClasses = useMemo(() => {
    switch (size) {
      case ButtonSize.S:
        return `text-dpm-14 py-1 px-2 ${!ignoreMinWidth ? 'min-w-20' : ''}`;
      default:
      case ButtonSize.M:
        return `text-dpm-16 py-2 px-4 ${!ignoreMinWidth ? 'min-w-32' : ''}`;
    }
  }, [ignoreMinWidth, size]);

  return (
    <div className={constainerClassName}>
      <Tooltip text={title}>
        {(tooltip) => (
          <button
            data-toggle-enabled={toggleEnabled}
            {...tooltip}
            className={`transform whitespace-nowrap transition-all duration-300 ease-in-out ${buttonClasses} ${buttonSizeClasses} ${disabledClasses} ${borderClass} ${className}`}
            name={name}
            type={htmlType}
            disabled={disabled || loading}
            onClick={onClick}
            tabIndex={0}
            {...dataAttributeProps(props)}
            {...ariaAttributeProps(props)}
          >
            {loading && (
              <div className="flex items-center justify-center">
                <Loader size={6} centered={false} />
              </div>
            )}

            {!loading && (
              <div className="flex justify-center">
                {IconTemplate() && (
                  <span className="pr-2">
                    <IconTemplate />
                  </span>
                )}
                <div className="button-text relative flex items-center justify-center">{children}</div>
              </div>
            )}
          </button>
        )}
      </Tooltip>
    </div>
  );
});

export default Button;
