import { FC, ReactNode, useMemo } from 'react';
import { dataAttributeProps } from '../../../utils/ComponentUtils';
import Tooltip from '../Tooltip';
import InfoIcon from '../icon/InfoIcon';

export type StatusTagProps = {
  text: string;
  className?: string;
  statusType: StatusVariant;
  bottomText?: string;
  infoText?: ReactNode;
  preserveSpace?: boolean;
  align?: 'center' | 'left';
};

export enum StatusVariant {
  GREEN,
  ORANGE,
  GRAY,
  RED,
  BLUE,
  GOLD,
}

const StatusTag: FC<StatusTagProps> = (props) => {
  const { text, className, statusType, bottomText, infoText, preserveSpace = true, align = 'center' } = props;

  const textColorCss = useMemo(() => {
    switch (statusType) {
      default:
      case StatusVariant.RED: {
        return 'text-status-tag-1';
      }
      case StatusVariant.GRAY: {
        return 'text-status-tag-2';
      }
      case StatusVariant.ORANGE: {
        return 'text-status-tag-3';
      }
      case StatusVariant.GREEN: {
        return 'text-status-tag-4';
      }
      case StatusVariant.GOLD: {
        return 'text-status-tag-5';
      }
      case StatusVariant.BLUE: {
        return 'text-status-tag-6';
      }
    }
  }, [statusType]);

  return (
    <div className={`flex flex-col ${align === 'left' ? 'items-start justify-start' : 'items-center justify-center'} gap-2`}>
      <div className={`flex flex-col items-center ${align === 'left' ? 'justify-start' : 'justify-center'} gap-2`}>
        <div className={`flex items-center ${align === 'left' ? 'self-start' : 'self-center'}`}>
          <div className={`bg-background-1 inline-flex flex-row items-center rounded-[5px] px-3 py-1 ${className}`}>
            <div {...dataAttributeProps(props)} className={`text-dpm-14 w-full text-center font-medium ${textColorCss}`}>
              {text}
            </div>
          </div>
          <div className={infoText ? '' : preserveSpace ? 'invisible' : 'hidden'}>
            <Tooltip text={infoText}>
              {(tooltip) => (
                <span {...tooltip}>
                  <InfoIcon className="h-6 w-6" />
                </span>
              )}
            </Tooltip>
          </div>
        </div>
        {align === 'left' && <div className={`text-dpm-12 -mb-2 text-left empty:hidden`}>{bottomText}</div>}
      </div>
      {align === 'center' && <div className={`text-dpm-12 -mb-2 text-center empty:hidden`}>{bottomText}</div>}
    </div>
  );
};

export default StatusTag;
