import { ApiResponse } from '../models/ApiResponse';
import { UserProfile } from '../models/AuthModels';
import User, { CurrentUserReponse } from '../models/User';
import { UserPreferences } from '../models/UserPreferences';
import BaseService from './BaseService';

class CurrentUserService extends BaseService {
  public static getPreferences(): Promise<ApiResponse<UserPreferences>> {
    return this.get('/v1/my/preferences');
  }
  public static savePreferences(data: UserPreferences): Promise<ApiResponse<boolean>> {
    return this.put('/v1/my/preferences', { preferences: data });
  }

  public static updateProfile(body: UserProfile): Promise<ApiResponse<User>> {
    return this.put('/v1/my/profile', body);
  }

  public static getProfile(): Promise<ApiResponse<CurrentUserReponse>> {
    return this.get('/v1/my/profile');
  }
}

export default CurrentUserService;
